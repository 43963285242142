// Define the name of the cache
const cacheName: string = 'v1';

// Define the files you want to cache
const cacheFiles: string[] = ['/', '/index.css', 'index.html'];

declare const self: ServiceWorkerGlobalScope;

self.addEventListener('beforeinstallprompt', (event) => {
    console.log('preinstall firing; "beforeinstallevent": ', event);
});

// The following "event" should be of type FetchEvent but we're experiencing bugs in type casting
/* self.addEventListener('fetch', (event) => {
    const url = new URL(event.request.url);
    console.log('request url is: ', url);

    // Check if the request is a GET request and the path contains "storage.googleapis"
    if (event.request.method == 'GET' && url.host == 'storage.googleapis.com') {
        event.respondWith(removeFileCachingForAdminRequests(event.request));
    }
}); */

/**
 * Modifies the 'Cache-Control' header in outgoing requests to 'no-store', and then sends it.
 *
 * @async
 * @param {Request} originalRequest - The original request to be cloned and modified.
 * @return {Promise<Response>} The response from the modified request.
 * @throws Will throw an error if the fetch operation fails.
 */
/* async function removeFileCachingForAdminRequests(
    originalRequest: Request,
): Promise<Response> {
    const requestClone = originalRequest.clone();
    const newHeaders = new Headers(requestClone.headers);
    newHeaders.set('Cache-Control', 'no-store,max-age=0');

    const newRequest = new Request(requestClone, { headers: newHeaders });
    const response = await fetch(newRequest);
    console.log('response: ', response);
    return response;
} */

self.addEventListener('install', (event /* event: ExtendableEvent */): void => {
    console.log('[Service Worker]: Installed: ', event);

    // Wait until promise is finished
    event.waitUntil(
        caches
            .open(cacheName)
            .then((cache: Cache) => {
                // console.log('[Service Worker]: Caching Files');
                cache.addAll(cacheFiles);
            })
            .then(() => self.skipWaiting()),
    );
});

self.addEventListener(
    'activate',
    (event /* event: ExtendableEvent */): void => {
        // console.log('[Service Worker]: Activated: ', event);

        // Remove unwanted old caches
        event.waitUntil(
            caches.keys().then((cacheNames: string[]) => {
                return Promise.all(
                    cacheNames.map((cache: string) => {
                        if (cache !== cacheName) {
                            console.log('[Service Worker]: Clearing Old Cache');
                            return caches.delete(cache);
                        }
                    }),
                );
            }),
        );
    },
);
